<template>
    <v-container>
      <div class="d-flex justify-space-between header">
        <div class="headSides d-flex flex-column justify-center" @mouseleave="hideLangs()">
          <div class="langSelect pointer d-flex" @mouseover="showLangs()" >
            <div class="mr-5" @click="selectLang('et')" v-if="this.$i18n.locale=='en'">Est</div>
            <div @click="selectLang('en')" v-else-if="this.$i18n.locale=='et'">Eng</div>
          </div>  
        </div>
        <div class=" text-h2 text-center">{{title}}</div>
        <div class="d-flex flex-column justify-center headSides">
          <div class="d-flex">
            <v-img
              :src="require('../assets/icons/facebook.svg')"
              class="mx-3 my-auto"
              max-width="10px"
              max-height="20px"
              eager
            />
            <v-img
              :src="require('../assets/icons/twitter.svg')"
              class="mx-3 my-auto"
              max-width="20px"
              max-height="20px"
              eager
            />
            <v-img
              :src="require('../assets/icons/instagram.svg')"
              class="mx-3 my-auto"
              max-width="20px"
              max-height="20px"
              eager
            />
            <v-img
              :src="require('../assets/icons/linkedin.svg')"
              class="mx-3 my-auto"
              max-width="20px"
              max-height="20px"
              eager
            />
            <v-btn
              color="action2"
              dark
              small
              class="ml-5"
              @click="$vuetify.goTo('#contacts', scrollOptions)"
            >
              {{$t("CONTACT")}}
            </v-btn>
          </div>
          

        </div>
      </div>
    </v-container>
</template>

<script>
import Cookies from 'universal-cookie';
  export default {
    name: 'Header',
    props:["title","scrollOptions"],
    components:{},
    data(){
      return {
        langsVisible:false,
      }
    },
    methods:{
      showLangs(){
        this.langsVisible=true;
      },
      hideLangs(){
        this.langsVisible=false;
      },
      selectLang(lang){
        this.$i18n.locale=lang;
        const cookies = new Cookies();
        cookies.set('locale', lang);
      }
    }
  }
</script>
<style >
  
</style>