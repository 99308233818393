<template>
    <div
        class="v-shaped elevation-3" :class="reversed?'reversed':''"
    >
        <v-img
            :src="imgSrc"
            :lazy-src="imgSrc"
            class=""
            max-width="40vw"
            eager
        />
    </div>
</template>

<script>
  export default {
    name: 'ShapedImg',
    props:["imgSrc","reversed"],
  }
</script>
<style >

</style>