<template>
    <v-row class="footer" id="footer">
        <v-container class="d-flex justify-center">
        <v-col
            cols="3"
        >
            
            <div class="text-h4 pt-10 mb-5">VÕTA ÜHENDUST</div>
            <div class="paragraph">
                T: +372 555 5555<br>
                M: info@jõeääre.ee<br>
                <br>
                A: Jõeääre , Rapla<br>
                Raplamaakond 78321 Estonia
            </div>
            <div class="subHeading mb-15 mt-2">HANGI SÕIDUJUHISED</div>
        </v-col>
        <v-col cols="6"></v-col>
        <v-col
            cols="3"
        >
            <div class="text-h4 pt-10 mb-5">LIITU UUDISKIRJAGA</div>
            <div class="paragraph text-caption">Liitu uudiskirjaga, et saada esimesena informatsiooni Jõeääre Kodude kohta. </div>
            <div class="d-flex inputGroup">
                <v-text-field
                    label="Sinu E-mail"
                    color="dark"
                    class="inputToBtn"
                ></v-text-field>
                <v-btn
                    class="btnToInput"
                    depressed
                >
                    <v-img
                        :src="require('../assets/icons/send.svg')"
                        eager
                    />
                </v-btn>

            </div>
            
            <div class="text-h5 pt-10 mb-5">JÄLGI MEID</div>
        </v-col>
        </v-container>
    </v-row>
    
</template>

<script>
  export default {
    name: 'Footer',
    components:{
    },
    data(){
      return {

      }
    },
  }
</script>
<style >

</style>