<template>
    <v-btn
      dark
      color="action"
      class="mt-10 actionButton"
      @click="$vuetify.goTo(target, scrollOptions)"
    >
      {{name}}
    </v-btn>
</template>

<script>
  export default {
    name: 'ActionBtn',
    props:["name","target","scrollOptions"],
  }
</script>
<style >

</style>