<template>
  <div>
  <v-container>
    
    <Header :title="'Jõeääre '+$t('HOMES')" class="mt-3" style="height:100px" :scrollOptions="scrollOptions"/>
    

    <!-- ARENDUS -->
    <v-row class="jIntro">
        <v-col
            cols="4"
        >
            <div class="subHeading my-15">JÕEÄÄRE {{$t("DEVELOPMENT")}}</div>
            <div class="text-h3 mb-5" v-html="$t('HEADING.REAL_HOME')"></div>
            <div class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius itaque perspiciatis quos reiciendis hic, laudantium sapiente et praesentium facilis tempora quae quibusdam quas eum laborum sequi deserunt? Voluptatibus, veritatis dolor?</div>
            <ActionBtn :name="$t('REGISTER_INTEREST')" target="#contacts" :scrollOptions="scrollOptions"/>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col
            cols="6"
            class="d-flex"
        >
        <ShapedImg :imgSrc="require('../assets/images/RabavRaplamaa-raba_siim-Solman.png')" />
        
        </v-col>
        <div style="position:relative;bottom:0;left: 0;width:100%" class="d-flex justify-center" >
          <div style="position:relative;" class="d-flex flex-column justify-center pointer" @click="$vuetify.goTo('#sector-2', scrollOptions)">
          {{$t("SCROLL_AHEAD")}}
              <div class="moveForward"></div>
          </div>
      </div>
    </v-row>
  </v-container>

  <!-- OMA KODU -->
  <v-row class="jGraybg" id="sector-2">
    <v-container class="d-flex justify-center">
      <v-col
          cols="8"
          offset="1"
      >
          <div class="subHeading mb-15 mt-2">{{$t("OWN_HOME")}}</div>
          <div class="text-h2 pt-10 mb-5" v-html="$t('HEADING.CITY_IS_CLOSE')"></div>
          <div class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius itaque perspiciatis quos reiciendis hic, laudantium sapiente et praesentium facilis tempora quae quibusdam quas eum laborum sequi deserunt? Voluptatibus, veritatis dolor?</div>
          
      </v-col>
    </v-container>
  </v-row>

  <v-container>

    <!-- ARHITEKTUUR -->
    <v-row class="mt-10">
      <v-col
            cols="6"
            class="d-flex"
        >
        <ShapedImg :imgSrc="require('../assets/images/hutomo-abrianto-X5BWooeO4Cw-unsplash 1.png')" reversed="true"/>
        
        </v-col>
        
        <v-col cols="1"></v-col>
        <v-col
            cols="4"
        >
            <div class="subHeading my-5">TÕELINE VÄÄRTUS</div>
            <div class="text-h3 mb-5">AVARUS VALGUS<br>JA KAASAEGNE<br>ARHITEKTUUR</div>
            <div class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius itaque perspiciatis quos reiciendis hic, laudantium sapiente et praesentium facilis tempora quae quibusdam quas eum laborum sequi deserunt? Voluptatibus, veritatis dolor?</div>
            <ActionBtn name="Vaata edasi" target="#theresMore" :scrollOptions="scrollOptions"/>
        </v-col>
        
    </v-row>
    <v-row class="mt-10 mb-15 pb-10">
        <v-col
            cols="4"
            class="d-flex flex-column justify-center"
            id="theresMore"
        >
            <div class="text-h2 mt-15 mb-5">MEILT OSTAD KODU,<br>MIS ON HOOLEGA<br>LOODUD</div>
            <ActionBtn name="Registreeri huvi" target="#contacts" :scrollOptions="scrollOptions"/>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col
            cols="6"
            class="d-flex"
        >
        <ShapedImg :imgSrc="require('../assets/images/matthew-smith-Rfflri94rs8-unsplash1.png')" />
        
        </v-col>
        
    </v-row>
  </v-container>

  <!-- STEPS -->
  <v-row class="jGraybg mb-15">
    <v-container class="d-flex justify-center">
      <v-col
          cols="8"
      >
          <div class="text-h2 pt-10 mb-5 text-center">JÕEÄÄRE KODUDE<br>ARENDUS</div>
          <Steps />
      </v-col>
    </v-container>
  </v-row>
    
    <!-- CONTACTS -->
    <v-container>
    <v-row class="mt-15">
      <v-col
            cols="6"
            class="d-flex"
            id="contacts"
        >
         <v-img
            :src="require('../assets/images/map.png')"
            :lasy-src="require('../assets/images/map.png')"
            class=""
            eager
            
        />
        
        </v-col>
        
        <v-col cols="1"></v-col>
        <v-col
            cols="4"
        >
            <div class="subHeading my-5">REGISTREERI HUVI</div>
            <div class="text-h3 mb-5">TULE TUTVUMA</div>
            <div class="paragraph">
              T: +372 555 5555<br>
              E: info@jõeääre.ee<br>
              <br>
              A: Jõeääre , Rapla<br>
              Raplamaakond 78321 Estonia
            </div>
        </v-col>
        
    </v-row>
    
      
    </v-container>
    <v-row class="jGraybg pt-15 contacts">
      <v-container class="d-flex justify-center">
        <v-col
            cols="7"
            offset="3"
            style="z-index:999"
        >
            <div class="contactForm">
              <div class="subHeading my-5">REGISTREERI HUVI</div>
              <div class="text-h4 mb-5">JÄTA ENDA KONTAKTID JA LISA OMA SOOVID</div>
                 
              <v-text-field
                label="Nimi*"
                color="white"
                v-model="contactName"
                :rules="[mustBeFilled]"
              ></v-text-field> 
              <v-text-field
                label="E-mail*"
                color="white"
                v-model="contactMail"
                autocomplete="off"
                :rules="[mustBeFilledEmail]"
              ></v-text-field>
              <v-text-field
                label="Telefon"
                color="white"
                v-model="contactPhone"
              ></v-text-field>
              <v-textarea
                label="Kirja sisu"
                rows="4"
                row-height="15"
                color="white"
                v-model="contactInfo"
              ></v-textarea>
              <v-btn
                class="square"
                color="action2outline"
                @click="sendEmail()"
                outlined
                tile
                :loading="loading"
                :disabled="loading"
              >
                SAADA KIRI
              </v-btn>
            </div>
            <v-snackbar
              v-model="emailSent"
              multi-line
              right
              :color="emailSendingState=='success'?'success':'error'"
              class="text-h3 primary--text"
            >
              <span v-if="emailSendingState=='success'" class="white--text text-subtitle-1">Sinu andmed on edastatud</span>
              <span v-else class="white--text text-subtitle-1">Andmete edastamine ebaõnnestus</span>
              
            </v-snackbar>
        </v-col>
        
      </v-container>
      <div style="position:relative;bottom:0;left: 0;width:100%" class="d-flex justify-center mt-10 mb-5" >
          <div style="position:relative;" class="d-flex flex-column justify-center pointer" @click="$vuetify.goTo(0, scrollOptions)">
            <div class="moveBackUp"></div>
            Tagasi üles
              
          </div>
      </div>
    </v-row>

    <Footer />
    
  </div>
</template>
<script>
import ShapedImg from "@/components/ShapedImg.vue";
import ActionBtn from "@/components/ActionBtn.vue";
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import Steps from "@/components/Steps.vue"
  export default {
    name: 'Joeaare',
    components:{
      ShapedImg,
      ActionBtn,
      Header,
      Steps,
      Footer,
    },
    data(){
      return {
        contactName:"",
        contactPhone:"",
        contactMail:"",
        contactInfo:"",
        mustBeFilled: value => !!value || 'Kohustuslik väli.',
        mustBeFilledEmail: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Vigane e-mail.'
        },
      }
    },
    computed:{
      scrollOptions () {
        return {
          duration: 700,
          offset: 0,
          easing: "easeOutQuart",
        }
      },
      loading(){
        return this.$store.getters.loading
      },
      emailSendingState(){
        return this.$store.getters.emailSendingState
      },
      emailSent(){
        return this.$store.getters.emailSent
      }
    },
    methods:{
      sendEmail(){
        if(this.contactName.trim()!="" &&
        this.contactMail.trim()!=""){
          this.$store.dispatch("sendEmail",{
            name:this.contactName,
            email:this.contactMail,
            phoneno:this.contactPhone,
            info:this.contactInfo
          })
        }
        
        
      }
    }
  }
</script>
<style >
</style>