<template>
    <div class="d-flex justify-center steps mt-10">
      
      <div class="step elevation-5 d-flex flex-column justify-center">
        <div class="stepHead font-weight-bold text-h4">7</div>
        <div class="stepName text-caption">Kinnistut</div>
      </div>

      <v-img
          :src="require('../assets/stepDown.svg')"
          class="mt-15"
          width="150"
          height="80"
      />
      <div class="step elevation-5 d-flex flex-column justify-center">
        <div class="stepHead font-weight-bold text-h4">3000
          <span class="stepHeadUnit font-weight-bold text-subtitle-1">m2</span>
          </div>
        <div class="stepName text-caption">Kinnistu suurused</div>
      </div>
      <v-img
          :src="require('../assets/stepUp.svg')"
          class="mt-15"
          width="150"
          height="80"
      />
      <div class="step elevation-5 d-flex flex-column justify-center">
        <div class="stepHead font-weight-bold text-h4">7</div>
        <div class="stepName text-caption">Eramut</div>
      </div>
      <v-img
          :src="require('../assets/stepDown.svg')"
          class="mt-15"
          width="150"
          height="80"
      />
      <div class="step elevation-5 d-flex flex-column justify-center">
        <div class="stepHead font-weight-bold text-h4">A</div>
        <div class="stepName text-caption">Egergiatähis</div>
      </div>


    </div>
    
</template>

<script>
  export default {
    name: 'Steps',
    props:["title"],
    components:{
    },
    data(){
      return {

      }
    },
  }
</script>
<style >

</style>